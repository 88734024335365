import axios from '@axios'
import config from "@/config";

export default {
  namespaced: true,
  state: {
    favorites: [],
    name: "John Doe",
    
  },
  getters: {},
  mutations: {
    UPDATE_FAVORITES(state, data) {
      state.favorites = data
    }
  },
  actions: {
    fetchPermissions() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.API_BASE_URL}/api/auth/UserPerms/${JSON.parse(localStorage.getItem('userData')).id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('http://localhost:8000/api/auth/ListProducts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProductFact(ctx,{ factId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`http://localhost:8000/api/auth/getProductFact/${factId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addToFavorites(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`http://localhost:8000/api/auth/getProductFact/39`)
          .then(response => 
            ctx.commit('UPDATE_FAVORITES', response.data),
            resolve(response)
            )
          .catch(error => reject(error))
      })
    }

    // fetchWishlistProducts() {
    //     return new Promise((resolve, reject) => {
    //       axios
    //         .get('/apps/ecommerce/wishlist')
    //         .then(response => resolve(response))
    //         .catch(error => reject(error))
    //     })
    //   },
    


  },
}
