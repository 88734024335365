import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import config from '@/config'
import axios from '@axios'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import VueMousetrapPlugin from 'vue-mousetrap/vue2'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)


import Form  from 'vform'
window.Form = Form;
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueMousetrapPlugin)

Vue.config.productionTip = false

var userPermissions = []
if(localStorage.getItem('userData')!== null){
  axios.get(`${config.API_BASE_URL}/api/auth/Perms/${JSON.parse(localStorage.getItem('userData')).id}`).then(function (response){
    userPermissions=response.data;
    console.log(userPermissions)
    localStorage.setItem('access', JSON.stringify(userPermissions))
  })
  
}

Vue.mixin({
  methods: {
    
     can: (key) => userPermissions.includes(key),
    //  Perm: () => {
    //   if(localStorage.getItem('userData')!== null){
    //     axios.get(`${config.API_BASE_URL}/api/auth/Perms/${JSON.parse(localStorage.getItem('userData')).id}`).then(function (response){
    //       // userPermissions=response.data;
    //       console.log(userPermissions)
    //       localStorage.setItem('access', JSON.stringify(userPermissions))
    //     })
        
    //   }
    //  }
    
    //  Navcan: () => 

  }
})
new Vue({
  router,
  store,
  i18n,
  config,
  render: h => h(App),
}).$mount('#app')
