export default [
  // {
  //   path: '/components/alert',
  //   name: 'components-alert',
  //   component: () => import('@/views/components/alert/Alert.vue'),
  //   meta: {
  //     pageTitle: 'Alerts',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Alerts',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/aspect',
  //   name: 'components-aspect',
  //   component: () => import('@/views/components/aspect/Aspect.vue'),
  //   meta: {
  //     pageTitle: 'Aspect',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Aspect',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/avatar',
  //   name: 'components-avatar',
  //   component: () => import('@/views/components/avatar/Avatar.vue'),
  //   meta: {
  //     pageTitle: 'Avatar',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Avatar',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/badge',
  //   name: 'components-badge',
  //   component: () => import('@/views/components/badge/Badge.vue'),
  //   meta: {
  //     pageTitle: 'Badge',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Badge',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/breadcrumb',
  //   name: 'components-breadcrumb',
  //   component: () => import('@/views/components/breadcrumb/Breadcrumb.vue'),
  //   meta: {
  //     pageTitle: 'Breadcrumb',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Breadcrumb',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/button',
  //   name: 'components-button',
  //   component: () => import('@/views/components/button/Button.vue'),
  //   meta: {
  //     pageTitle: 'Button',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Button',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/button-group',
  //   name: 'components-button-group',
  //   component: () => import('@/views/components/button-group/ButtonGroup.vue'),
  //   meta: {
  //     pageTitle: 'Button Group',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Button Group',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/button-toolbar',
  //   name: 'components-button-toolbar',
  //   component: () => import('@/views/components/button-toolbar/ButtonToolbar.vue'),
  //   meta: {
  //     pageTitle: 'Button Toolbar',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Button Toolbar',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/calendar',
  //   name: 'components-calendar',
  //   component: () => import('@/views/components/calendar/Calendar.vue'),
  //   meta: {
  //     pageTitle: 'Calendar',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Calendar',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/carousel',
  //   name: 'components-carousel',
  //   component: () => import('@/views/components/carousel/Carousel.vue'),
  //   meta: {
  //     pageTitle: 'Carousel',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Carousel',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/collapse',
  //   name: 'components-collapse',
  //   component: () => import('@/views/components/collapse/Collapse.vue'),
  //   meta: {
  //     pageTitle: 'Collapse',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Collapse',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/dropdown',
  //   name: 'components-dropdown',
  //   component: () => import('@/views/components/dropdown/Dropdown.vue'),
  //   meta: {
  //     pageTitle: 'Dropdown',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Dropdown',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/embed',
  //   name: 'components-embed',
  //   component: () => import('@/views/components/embed/Embed.vue'),
  //   meta: {
  //     pageTitle: 'Embed',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Embed',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/image',
  //   name: 'components-image',
  //   component: () => import('@/views/components/image/Image.vue'),
  //   meta: {
  //     pageTitle: 'Image',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Image',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/list-group',
  //   name: 'components-list-group',
  //   component: () => import('@/views/components/list-group/ListGroup.vue'),
  //   meta: {
  //     pageTitle: 'List Group',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'List Group',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/media',
  //   name: 'components-media',
  //   component: () => import('@/views/components/media/Media.vue'),
  //   meta: {
  //     pageTitle: 'Media Objects',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Media Objects',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/modal',
  //   name: 'components-modal',
  //   component: () => import('@/views/components/modal/Modal.vue'),
  //   meta: {
  //     pageTitle: 'Modal',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Modal',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/nav',
  //   name: 'components-nav',
  //   component: () => import('@/views/components/nav/Nav.vue'),
  //   meta: {
  //     pageTitle: 'Nav',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Nav',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/overlay',
  //   name: 'components-overlay',
  //   component: () => import('@/views/components/overlay/Overlay.vue'),
  //   meta: {
  //     pageTitle: 'Overlay',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Overlay',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/pagination',
  //   name: 'components-pagination',
  //   component: () => import('@/views/components/pagination/Pagination.vue'),
  //   meta: {
  //     pageTitle: 'Pagination',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Pagination',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/pagination-nav',
  //   name: 'components-pagination-nav',
  //   component: () => import('@/views/components/pagination-nav/PaginationNav.vue'),
  //   meta: {
  //     pageTitle: 'Pagination Nav',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Pagination Nav',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/pill',
  //   name: 'components-pill',
  //   component: () => import('@/views/components/pill/Pill.vue'),
  //   meta: {
  //     pageTitle: 'Pill',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Pill',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/pill-badge',
  //   name: 'components-pill-badge',
  //   component: () => import('@/views/components/pill-badge/PillBadge.vue'),
  //   meta: {
  //     pageTitle: 'Pill Badge',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Pill Badge',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/popover',
  //   name: 'components-popover',
  //   component: () => import('@/views/components/popover/Popover.vue'),
  //   meta: {
  //     pageTitle: 'Popover',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Popover',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/progress',
  //   name: 'components-progress',
  //   component: () => import('@/views/components/progress/Progress.vue'),
  //   meta: {
  //     pageTitle: 'Progress',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Progress',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/sidebar',
  //   name: 'components-sidebar',
  //   component: () => import('@/views/components/sidebar/Sidebar.vue'),
  //   meta: {
  //     pageTitle: 'Sidebar',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Sidebar',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/spinner',
  //   name: 'components-spinner',
  //   component: () => import('@/views/components/spinner/Spinner.vue'),
  //   meta: {
  //     pageTitle: 'Spinner',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Spinner',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/tab',
  //   name: 'components-tab',
  //   component: () => import('@/views/components/tab/Tab.vue'),
  //   meta: {
  //     pageTitle: 'Tab',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Tab',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/time',
  //   name: 'components-time',
  //   component: () => import('@/views/components/time/Time.vue'),
  //   meta: {
  //     pageTitle: 'Time',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Time',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/timeline',
  //   name: 'components-timeline',
  //   component: () => import('@/views/components/timeline/Timeline.vue'),
  //   meta: {
  //     pageTitle: 'Timeline',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Timeline',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/toasts',
  //   name: 'components-toasts',
  //   component: () => import('@/views/components/toasts/Toasts.vue'),
  //   meta: {
  //     pageTitle: 'Toasts',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Toasts',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/components/tooltip',
  //   name: 'components-tooltip',
  //   component: () => import('@/views/components/tooltip/Tooltip.vue'),
  //   meta: {
  //     pageTitle: 'Tooltip',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Tooltip',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
