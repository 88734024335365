import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '@/config'
import axios from '@axios'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser,Access } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import admin from './routes/admin'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...admin,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if(localStorage.getItem('userData')!== null){
    axios.post(`${config.API_BASE_URL}/api/auth/CheckUser?email=${JSON.parse(localStorage.getItem('userData')).email}&id=${JSON.parse(localStorage.getItem('userData')).id}`).then(res =>{
      if(res.data.message=='success'){
        axios.get(`${config.API_BASE_URL}/api/auth/Perms/${res.data.user.id}`).then(function (response){
          // userPermissions=response.data;
          localStorage.setItem('access', JSON.stringify(response.data))
        })
      }else{
        localStorage.clear();
        next({ name: 'auth-login' })
      }
    })
  }

  if (!canNavigate(to)) {
    // localStorage.getItem('userData')
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    
    return next({ name: 'auth-login' })
  }


  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    const Access= Access()
    next(getHomeRouteForLoggedInUser(userData ? Access : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
